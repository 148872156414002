<template>
  <div class="promo-view" v-if="post">
    <default-space>
      <div class="d-md-flex">
        <div class="promo-carousel-container" ref="promo_carousel_container" :style="carouselHeight">
          <div class="promo-image-carousel">
            <promo-image-carousel
                :images="images"
                :height="carouselHeight"
                :width="carouselWidth"
            />
          </div>
        </div>
        <div class="promo-content-container">
          <div class="favorite-circle">
            <i
                :class="{'active icon-heart-2': post.is_favourite, 'icon-heart': !post.is_favourite}"
                @click="processFavourite"
            />
          </div>

          <div class="promo-title">
            {{ title }}
          </div>

          <div class="price">
            <template v-if="post.old_price">
              <span class="price--old">
                € {{ post.old_price }}
              </span>
              <span class="price--separator"> | </span>
            </template>

            <span v-if="post.new_price" class="price--new">
              € {{ post.new_price }}
            </span>
            <span v-if="post.discount" class="price--discount">
              {{ $t('post.discount') }}: {{ post.discount }}
            </span>
          </div>

          <div class="promo-description">
            <dynamic-content :content="post.content"/>
          </div>

          <template v-if="post.free_shipping">
            <span class="promo-free-shipping">
              {{ shippingNumber }}
            </span>
          </template>

          <div class="promo-tags">
            <hash-tags :tags="tags"/>
          </div>
          <div class="promo-discount">
            <copied-code-button
                v-if="discountCodeCopied && (post.discount_code || post.discount_codes_count)"
                :code="post.discount_code"
                :id="post.id"
            />
            <copy-code-button
                v-else-if="post.discount_code || post.discount_codes_count"
                :id="post.id"
                :code="post.discount_code"
                :url="post.discount_url"
                :event-data="post.meta_properties"
            />
            <go-webshop-button
                v-else-if="webshopUrl !== null"
                :url="webshopUrl"
                :event-data="post.meta_properties"
            />
          </div>

          <div class="d-flex justify-content-start">
            <router-localized-link :to="{name: 'profile', params: {userId: String(post.user.id)}}">
              <el-avatar
                  :src="post.user.avatar_url"
                  shape="circle"
                  class="promo-author-avatar"
              />
            </router-localized-link>

            <div class="promo-author">
              <div class="promo-author-top-container">
                <div class="promo-author-name">
                  <router-localized-link :to="{name: 'profile', params: {userId: String(post.user.id)}}">
                    {{ post.user.full_name }}
                  </router-localized-link>
                </div>
                <div class="dot-separator">•</div>
                <div class="promo-published-time">
                  {{ post.published_text }}
                </div>
              </div>
              <div class="promo-valid-time">
                {{ post.valid_text }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <reactions
          class="px-2"
          :rating="post.rating_calculated"
          :is-like="post.is_hit"
          :comments-count="commentsCount"
          @react="$refs.commentForm.focusTextarea()"
          @hit="processHit"
          @share="processShare"
      />

      <div class="promo-footer">
        <div class="comments-wrapper mt-1">
          <comments-header :hidden-total="hiddenComments" :total="commentsCount" @show-more="showMoreComments"/>

          <comments-loading :loading="commentsLoading"/>

          <comments :comments-data="visibleCommentsData" @hit="hitCommentProcess"/>

          <comments-loading :loading="commentsPushing"/>

          <comment-form ref="commentForm" @submit="pushComment"/>
        </div>
      </div>
    </default-space>
  </div>
</template>

<script>
import post from '@/plugins/post';
import {actions} from '@/mixins/post/actions';
import {comments} from '@/mixins/comments';
import {slug} from '@/mixins/slug';
import {promo_carousel_container} from '@/mixins/promo/promo_carousel_container';
import {needAuth} from '@/mixins/auth/needAuth';
import {discountCodes} from '@/mixins/post/discountCodes';
import {mapActions, mapGetters} from 'vuex';
import store from '@/store';

import HashTags from '@/components/feed/post/HashTags';
import Reactions from '@/components/feed/Reactions';
import CommentsHeader from '@/components/feed/comment/CommentsHeader';
import CommentsLoading from '@/components/feed/comment/CommentsLoading';
import Comments from '@/components/feed/comment/Comments';
import CommentForm from '@/components/feed/comment/CommentForm';
import DefaultSpace from '@/views/DefaultSpace';
import DynamicContent from '@/components/feed/post/DynamicContent';
import CopiedCodeButton from '@/components/feed/buttons/CopiedCodeButton';
import CopyCodeButton from '@/components/feed/buttons/CopyCodeButton';
import GoWebshopButton from '@/components/feed/buttons/GoWebshopButton';
import PromoImageCarousel from '@/components/feed/promo/PromoImageCarousel';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';

export default {
  name: 'PostView',
  components: {
    PromoImageCarousel,
    GoWebshopButton,
    CopyCodeButton,
    CopiedCodeButton,
    DynamicContent,
    DefaultSpace,
    CommentForm,
    Comments,
    CommentsLoading,
    CommentsHeader,
    Reactions,
    HashTags,
    RouterLocalizedLink
  },
  mixins: [actions, comments, slug, needAuth, discountCodes, promo_carousel_container],
  data() {
    return {
      vuexModule: 'promo',
      discountCodeCopied: false
    }
  },
  computed: {
    ...mapGetters('promo', [
      'CURRENT_PROMO',
    ]),
    /**
     * Data for mixin
     * @returns {{morphable_type: string, entity: Object}}
     */
    entityData() {
      return {
        entity: this.post,
        morphable_type: 'Post'
      }
    },
    post() {
      return this.CURRENT_PROMO;
    },
    tags() {
      const result = [];
      if (_.get(this.post, 'type.id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'type.translation.alias')),
          link: this.$router.resolve({name: 'home'}).href,
          entityType: 'type'
        });
      }

      if (_.get(this.post, 'webshops[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'webshops[0].translation.title')),
          link: this.$router.resolve({
            name: 'webshop.show',
            params: {webshopSlug: _.get(this.post, 'webshops[0].translation.route')}
          }).href,
          entityType: 'webshop'
        });
      }

      if (_.get(this.post, 'stores[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'stores[0].translation.title')),
          link: this.$router.resolve({
            name: 'store.show',
            params: {storeSlug: _.get(this.post, 'stores[0].translation.route')}
          }).href,
          entityType: 'store'
        });
      }

      if (_.get(this.post, 'brands[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'brands[0].translation.title')),
          link: this.$router.resolve({
            name: 'brand.show',
            params: {brandSlug: _.get(this.post, 'brands[0].translation.route')}
          }).href,
          entityType: 'brand'
        });
      }

      if (_.get(this.post, 'products[0].id', false)) {
        result.push({
          text: this.convertToSlug(_.get(this.post, 'products[0].translation.title')),
          link: this.$router.resolve({
            name: 'product.show',
            params: {productSlug: _.get(this.post, 'products[0].translation.route')}
          }).href,
          entityType: 'product'
        });
      }

      return result;
    },
    images() {
      if (this.post.images.length) {
        return this.post.images;
      }
      if (this.post.webshop) {
        return [{src: this.post.webshop.image_url}];
      }
      return [{src: require('@/assets/images/promo_no_image.svg')}];
    },
    title() {
      return post.getTitle(this.post);
    },
    webshopUrl() {
      if (this.post.url !== null) {
        return this.post.url;
      }
      return _.get(this.post, 'webshops[0].translation.affiliate_url', null);
    },
    shippingNumber() {
      if (this.post.shipping_number === null){
        return this.$t('post.free_shipping');
      }
      return this.$t('post.shipping_number') + ' € ' + this.post.shipping_number;
    },
  },
  mounted() {
    this.commentsCount = this.post.comments_count;
    this.comments = this.post.comments;
    this.visibleComments = _.min([3, this.post.comments.length]);

    if (localStorage.getItem('CopiedDiscountCodeId')) {
      if (store.getters['auth/GUEST']) {
        setTimeout(() => {
          this.$dialogs.show('auth', 'default');
        }, 500);
      } else {
        this.loadDiscountCode(localStorage.getItem('CopiedDiscountCodeId'));
      }

      localStorage.removeItem('CopiedDiscountCodeId');
    }
  },
  methods: {
    ...mapActions('promo', [
      'FETCH_CURRENT_PROMO',
      'RESET_PROMOS',
    ]),
    openWebshop() {
      window.open(this.onlinePromo.url, '_blank');
    },
    showMoreComments() {
      this.showMoreCommentsProcess('Post', this.post.id);
    },
    pushComment(form) {
      this.pushCommentProcess('Post', this.post.id, form);
    },
    processShare(destination) {
      const link = new URL(
          this.$router.resolve({
            name: 'posts.show',
            params: {id: this.post.id}
          }).href,
          window.location.origin
      ).href;
      this.shareLink(link, destination);
    },
    loadDiscountCode(id) {
      axios.get('/feed-items?post=' + id).then((response) => {
        this.discountCode = _.get(response, 'data.data[0].data', null);
        if (this.discountCode) {
          this.discountCodeCopied = true;
        }
      });
    }
  }
}
</script>
