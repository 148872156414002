<template>
  <header class="header">
    <!--
      Header area
    -->
    <div class="header-area">
      <default-space>
        <div class="d-flex justify-content-between align-items-center ps-1 ps-sm-0">
          <router-localized-link class="header-area__title" :to="{name : 'home'}">
            <span>{{ $t('header.title') }}</span>
            <span v-if="!xsOnly" class="subtitle" v-t="'header.subtitle'"/>
          </router-localized-link>
          <div class="header-area__search">
            <ui-autocomplete ref="autocomplete"/>
          </div>
          <div class="header-area__actions">
            <div class="header-actions-wrapper ms-3.5">
              <template v-if="GUEST">
                <div class="login-text me-xl-4">
                  <router-localized-link v-t="'header.login_header'" :to="{name: 'login'}"/>
                  <i class="icon-account-2 ms-2"/>
                </div>
                <i class="font-weight-bold align-self-center"
                   :class="{'icon-menu': !mainMenuVisible, 'icon-close': mainMenuVisible}"
                   @click="$dialogs.toggle('main-menu')">
                </i>
              </template>
              <header-actions/>
            </div>
          </div>
        </div>
      </default-space>
    </div>

    <!--
      Header menu
    -->
    <div v-if="showMenu" class="header-menu">
      <default-space col-class="el-col-xs-24">
        <el-row justify="space-between">
          <div class="menu-list">
            <div class="menu-list-item" :class="{'menu-list-item__active': $route.name === 'home'}">
              <router-localized-link :to="{name : 'home'}">
                <i v-if="xsOnly" class="icon-home-1"/>
                <span v-t="'header.menu.home'"/>
              </router-localized-link>
            </div>

            <div class="menu-list-item" :class="{'menu-list-item__active': $route.name === 'promos'}">
              <router-localized-link :to="{name: 'promos'}">
                <i v-if="xsOnly" class="icon-percent1"/>
                <span v-t="'header.menu.promos'"/>
              </router-localized-link>
            </div>

            <div v-if="xsOnly" class="menu-list-item" @click="$dialogs.show('create-post')">
              <i class="icon-plus-1"/>
              <span v-t="'header.menu.plus'"/>
            </div>

            <div class="menu-list-item" :class="{'menu-list-item__active': $route.name === 'folders'}">
              <router-localized-link :to="{name: 'folders'}">
                <i v-if="xsOnly" class="icon-folder"/>
                <span v-t="'header.menu.folders'"/>
              </router-localized-link>
            </div>

            <div
              v-if="LOCALE === 'nl'"
              class="menu-list-item"
              :class="{'menu-list-item__active': $route.name === 'news'}"
            >
              <router-localized-link :to="{name: 'news'}">
                <i v-if="xsOnly" class="icon-bullhorn-2"/>
                <span v-t="'header.menu.news'"/>
              </router-localized-link>
            </div>
          </div>
          <div class="d-flex justify-content-end align-items-center">
            <div v-if="!xsOnly && !GUEST" class="menu-list-item" @click="$dialogs.show('create-post')">
              <button class="create-post-btn me-2.25">
                {{ $t('header.menu.create_promo') }}
                <i class="icon-plus-1"/>
              </button>
            </div>
            <locale-dropdown v-if="!xsOnly" btn-class="pe-0 justify-content-end"/>
          </div>
        </el-row>
      </default-space>
    </div>

    <div v-if="ACTIVE_FILTERS.ch_filter" class="categories-filter-wrapper">
      <categories-header-filter ref="categoryFilter"/>
    </div>
  </header>
  <breadcrumbs/>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import {needAuth} from '@/mixins/auth/needAuth';
import RouterLocalizedLink from '@/components/common/RouterLocalizedLink';
import UiAutocomplete from '@/components/header/UiAutocomplete.vue';
import HeaderActions from '@/components/header/HeaderActions.vue';
import DefaultSpace from '@/views/DefaultSpace.vue';
import LocaleDropdown from '@/components/header/dropdowns/LocaleDropdown.vue';

export default {
  name: 'main-header',
  components: {
    DefaultSpace,
    LocaleDropdown,
    HeaderActions,
    UiAutocomplete,
    RouterLocalizedLink,
  },
  mixins: [needAuth],
  data() {
    return {
      // showSearchbar: true,
      // showCategoriesFilter: true
    };
  },
  computed: {
    ...mapGetters('filter', [
      'ACTIVE_FILTERS',
    ]),
    ...mapGetters('auth', [
      'USER',
      'GUEST',
      'LOCALE',
    ]),
    ...mapState('dialogs', {
      mainMenuVisible: ({dialogs}) => dialogs['main-menu'].visible,
    }),
    /**
     * @returns {boolean|*|boolean}
     */
    showCloseSuggestions() {
      return this.xsOnly && this.popperFocused
    },
    /**
     * @returns {boolean}
     */
    showMenu() {
      const needHide = this.$route.name === 'folder.view' && this.mdAndUp;
      return !needHide;
    },
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_ACTIVE',
      'SET_FILTER_INACTIVE',
    ]),
  },
  watch: {
    currentScrollTop(newValue) {
      //
    }
  }
}
</script>
