<template>
  <el-dialog
    v-model="visible"
    :lock-scroll="true"
    width="100vw"
    modal-class="post-dialog"
    @close="onCloseDialog"
    @open="onOpenDialog"
    close-on-press-escape
    close-on-click-modal
    destroy-on-close
    show-close
    center
  >
    <template v-slot:header>
      <div class="position-relative">
        <span v-t="'post.new_post'"/>
        <div v-if="showCoinsInHeader" class="coins pt-1">
          <span class="me-1" v-text="bonus"/>
          <img class="h-100" src="@/assets/images/coins.png" alt="">
        </div>
      </div>
    </template>

    <progress-bar
      :step-class="{'progress-bar__step--success': postIsCreated}"
      v-model:current-step="step"
      :steps-count="stepsCount"
    />

    <component
      v-show="!postIsCreated"
      ref="component"
      class="post-step"
      :is="currentStepComponent"
      :title-number="titleNumber"
      @update="updateStep"
    />
    <success-creation
      v-show="postIsCreated"
      :bonus="finalBonus"
      @close="onCloseDialog"
    />

    <div class="mt-auto">
      <p v-if="showBonus" class="label-bonus">
        {{ bonusText }}
      </p>
      <div v-if="!postIsCreated" class="w-100 d-flex justify-content-center">
        <button v-if="showPrevious" class="nav-btn" @click="previousStep">{{ $t('post.previous') }}</button>
        <button v-if="showNext" class="nav-btn" @click="nextStep">{{ $t('post.next') }}</button>
        <button v-if="showSubmit" class="nav-btn" @click="submit">{{ $t('post.confirm') }}</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import ProgressBar from '@/components/feed/post/creation/ProgressBar.vue';
import SuccessCreation from '@/components/feed/post/creation/steps/SuccessCreation.vue';
import TypeStep from '@/components/feed/post/creation/steps/TypeStep.vue';
import ContentStep from '@/components/feed/post/creation/steps/ContentStep.vue';
import UploadImageStep from '@/components/feed/post/creation/steps/UploadImageStep.vue';
import OptionalContentStep from '@/components/feed/post/creation/steps/OptionalContentStep.vue';
import CategoriesStep from '@/components/feed/post/creation/steps/CategoriesStep.vue';

export default {
  name: 'post-dialog',
  components: {
    SuccessCreation,
    ProgressBar,
    TypeStep,
    CategoriesStep,
    ContentStep,
    UploadImageStep,
    OptionalContentStep
  },
  computed: {
    ...mapGetters('type', ['TYPES']),
    ...mapGetters('bonus_action', [
      'BONUS_ACTIONS',
      'BONUS_ACTION_TYPES',
    ]),
    ...mapState('dialogs', {
      visible: ({dialogs}) => dialogs['create-post'].visible,
    }),
    ...mapState('promo', {
      form: ({form}) => form,
      type: ({form}) => form.type,
      subtype: ({form}) => form.subtype,
    }),
    /**
     * Get number of first title in section
     * @returns {number}
     */
    titleNumber() {
      let res = 1;
      this.steps.forEach((stp, key) => {
        if (key < this.step) {
          res += stp.titlesCount || 0
        }
      })

      return res
    },
    /**
     * Steps count
     * @returns {*|number}
     */
    stepsCount() {
      const typeId = this.type.id || 1;

      return this.typesComponents[typeId].length
    },
    /**
     * Current step component for render
     * @returns {*}
     */
    currentStepComponent() {
      const arrIndex = this.step - 1;
      const typeId = this.type.id || 1;

      return this.typesComponents[typeId][arrIndex]
    },
    /**
     * Show btn to go on previous step
     * @returns {boolean}
     */
    showPrevious() {
      return this.step !== 1;
    },
    /**
     * Show btn to go on next step
     * @returns {boolean}
     */
    showNext() {
      return this.step !== this.stepsCount;
    },
    /**
     * Show submit button
     * @returns {boolean}
     */
    showSubmit() {
      return this.step === this.stepsCount;
    },
    /**
     * Bonus
     * @returns {string|*}
     */
    bonus() {
      return this.getRewardText(this.BONUS_ACTION_TYPES['post-created'], 'Type', this.subtype.id)
    },
    /**
     * Bonus preview text
     * @returns {string}
     */
    bonusText() {
      return `+ ${this.bonus} ${this.$t('bonus_system.balance_postfix')}`
    },
    /**
     * Show bonus text
     * @returns {*|boolean}
     */
    showBonus() {
      return this.subtype && this.bonus && this.bonus !== '0' && this.step === 1
    },
    /**
     * Show coins in header
     * @returns {boolean}
     */
    showCoinsInHeader() {
      return this.step !== 1 && !this.postIsCreated && this.bonus
    },
    /**
     * Mutate expired At
     * @returns {string}
     */
    expiredAt() {
      if (!this.form.expired_at) {
        return ''
      }

      const date = new Date(this.form.expired_at);
      date.setHours(date.getHours() + 23);
      date.setMinutes(date.getMinutes() + 59);

      return date.toISOString()
    },
    /**
     * Prepare form data
     */
    prepareData() {
      const data = {
        type_id: this.subtype.id,
        categories: this.form.categories,
        title: this.form.title,
        content: this.form.content,
        origin_url: this.form.origin_url,
        images: this.form.images,
        old_price: this.form.old_price,
        new_price: this.form.new_price,
        discount_value: this.form.discount_value,
        discount_type: this.form.discount_type,
        another_type: this.form.another_type,
        started_at: this.form.started_at,
        expired_at: this.expiredAt,
        free_shipping: this.form.free_shipping,
        shipping_number: this.form.shipping_number,
      }

      if (this.subtype.id === this.$consts.POST_TYPES.DISCOUNT) {
        data['discount_code'] = this.form.discount_code
        data['webshop_id'] = this.form.webshop_id
      }

      if (this.form.only_another_type) {
        delete data['old_price']
        delete data['new_price']
        delete data['discount_type']
        delete data['discount_value']
      }

      if (this.form.only_discount) {
        delete data['old_price']
        delete data['new_price']
        delete data['another_type']
      }

      if (this.form.only_price) {
        delete data['discount_type']
        delete data['discount_value']
        delete data['another_type']
      }

      if (!this.form.free_shipping) {
        data['shipping_number'] = ''
      }

      return data
    },
  },
  data() {
    return {
      step: 1,
      steps: [],
      typesComponents: {
        [this.$consts.POST_TYPES.REPORT]: ['TypeStep', 'CategoriesStep', 'ContentStep', 'UploadImageStep', 'OptionalContentStep'],
        [this.$consts.POST_TYPES.QUESTION]: ['TypeStep', 'CategoriesStep', 'ContentStep', 'UploadImageStep'],
        [this.$consts.POST_TYPES.BARGAIN]: ['TypeStep', 'CategoriesStep', 'ContentStep', 'UploadImageStep'],
      },
      postIsCreated: false,
      finalBonus: 0,
    }
  },
  mounted() {
    /**
     * Fetch types
     */
    if (!this.TYPES.length) {
      this.FETCH_TYPES()
    }
    /**
     * Fetch bonuses
     */
    if (!this.BONUS_ACTIONS.length) {
      this.FETCH_BONUS_ACTIONS();
    }
  },
  methods: {
    /**
     * mapActions
     */
    ...mapActions('type', ['FETCH_TYPES']),
    ...mapActions('bonus_action', ['FETCH_BONUS_ACTIONS']),
    ...mapActions('loader', ['SET_LOADER']),
    ...mapActions('promo', ['RESET_FORM', 'CREATE_PROMO']),
    /**
     * mapMutations
     */
    ...mapMutations('promo', {updateForm: 'UPDATE_FORM'}),
    /**
     * Go to next step
     */
    async nextStep() {
      const isValid = await this.$refs.component.validate()

      if (isValid && this.step < this.stepsCount) {
        this.step++
      }
    },
    /**
     * Go to previous step
     */
    previousStep() {
      if (this.step !== 0) {
        this.step--
      }
    },
    /**
     * Update step info
     * @param info
     */
    updateStep(info) {
      this.steps[this.step] = info
    },
    /**
     * Submit post
     */
    submit() {
      const isValid = this.$refs.component.validate()

      if (!isValid) {
        return
      }

      this.SET_LOADER(true);

      const data = this.prepareData

      this.CREATE_PROMO(data)
        .then((response) => {
          this.postIsCreated = true
          this.finalBonus = response.data.bonus_impact
        })
        .catch((e) => {
          const errors = e.response.data.errors
          switch (e.response.status) {
            case 422:
              Object.keys(errors).forEach((key) => {
                this.$notify.error({
                  title: 'Validation Error',
                  message: errors[key][0],
                  position: this.notificationPosition,
                  duration: 4000,
                });
              })
              break;

            default:
              Object.keys(errors).forEach((key) => {
                this.$notify.error({
                  title: 'Error',
                  message: errors[key][0],
                  position: this.notificationPosition,
                  duration: 4000,
                });
              })
          }
        })
        .then(() => this.SET_LOADER(false));
    },
    onOpenDialog() {
      /**
       * Fetch types
       */
      if (!this.TYPES.length) {
        this.FETCH_TYPES()
      }
    },
    /**
     * Close dialog event
     */
    onCloseDialog() {
      this.step = 1;
      this.RESET_FORM();
      this.postIsCreated = false;
      this.$dialogs.hide('create-post');
    },
    /**
     * Reward value
     * @param type
     * @param bonusable_type
     * @param bonusable_id
     * @returns {*|string}
     */
    getRewardText(type, bonusable_type = null, bonusable_id = null) {
      const action = this.BONUS_ACTIONS.find(need => need.type === type && need.bonusable_type === bonusable_type && need.bonusable_id === bonusable_id);

      if (undefined === action || action.impact_on_balance < 0) {
        return '0';
      }
      return action.impact_on_balance;
    },
  },
}
</script>
