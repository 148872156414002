<template>
  <default-grid :show-right-container="false">
    <template v-slot:main-container>
      <div class="promos-feed">
        <div class="d-flex align-items-center header-container">
          <h1 class="promos-feed-title font-weight-medium my-3" v-html="h1Text"></h1>
        </div>

        <tabs
          :tabs="tabs"
          :default="currentTab"
          @change="tabChange"
          change-query
        />
        <TransitionGroup tag="div" name="fade" class="mt-3 container promos-grid">
          <div v-for="(promo, i) in promos" :key="promo">
            <template v-if="'FolderItem' === promo.morphable_type">
              <folder-promo-horizontal
                v-if="xsOnly"
                :folder-promo="promo.data"
                :is-bang-promo="i < 5"
                :show-promoman="isPromomanVisible(i)"
                @opened="saveScrollPosition(i)"
                @voted="sortPromos"
              />
              <folder-promo-mini
                v-else
                :folder-promo="promo.data"
                :is-bang-promo="i < 5"
                :show-promoman="isPromomanVisible(i)"
                @opened="saveScrollPosition(i)"
                @voted="sortPromos"
              />
            </template>

            <template v-if="'OnlinePromo' === promo.morphable_type">
              <online-promo-horizontal
                v-if="xsOnly"
                :online-promo="promo.data"
                :is-bang-promo="i < 5"
                :show-promoman="isPromomanVisible(i)"
                @opened="saveScrollPosition(i)"
                @voted="sortPromos"
              />
              <online-promo-mini
                v-else
                :online-promo="promo.data"
                :is-bang-promo="i < 5"
                :show-promoman="isPromomanVisible(i)"
                @opened="saveScrollPosition(i)"
                @voted="sortPromos"
              />
            </template>

            <template v-if="'Post' === promo.morphable_type">
              <post-horizontal
                v-if="xsOnly"
                :post="promo.data"
                :is-bang-promo="i < 5"
                :show-promoman="isPromomanVisible(i)"
                @opened="saveScrollPosition(i)"
                @voted="sortPromos"
              />
              <post-mini
                v-else
                :post="promo.data"
                :is-bang-promo="i < 5"
                :show-promoman="isPromomanVisible(i)"
                @opened="saveScrollPosition(i)"
                @voted="sortPromos"
              />
            </template>
          </div>
        </TransitionGroup>

        <infinite-loading @infinite="load" :key="currentTab + infiniteKey">
          <template #spinner>
            <div class="spinner-container" v-loading="true"/>
          </template>
          <template #complete>
            <div class="color-primary font-size-18 my-3">
              <div v-if="!$store.getters['auth/GUEST']">
                <span v-t="'search.loading.no_results'" v-if="PROMOS_TOTAL < 1"/>
              </div>
            </div>
          </template>
          <template #error>
            <div></div>
          </template>
        </infinite-loading>
      </div>
    </template>
  </default-grid>
</template>

<script>
import DefaultGrid from '@/views/DefaultGrid';
import {mapActions, mapGetters} from 'vuex';
import Tabs from '@/components/feed/Tabs';
import InfiniteLoading from 'v3-infinite-loading';
import {useHead} from '@vueuse/head';
import {needAuth} from '@/mixins/auth/needAuth';
import FolderPromoMini from '@/components/feed/promo/FolderPromoMini.vue';
import OnlinePromoMini from '@/components/feed/promo/OnlinePromoMini.vue';
import PostHorizontal from '@/components/feed/horizontal/PostHorizontal.vue';
import PostMini from '@/components/feed/post/PostMini.vue';
import OnlinePromoHorizontal from '@/components/feed/promo/OnlinePromoHorizontal.vue';
import FolderPromoHorizontal from '@/components/feed/promo/FolderPromoHorizontal.vue';
import {breakpoint} from '@/mixins/public_spa_js/breakpoint';

export default {
  name: 'PromosView',
  components: {
    FolderPromoHorizontal,
    OnlinePromoHorizontal,
    PostMini,
    PostHorizontal,
    OnlinePromoMini,
    DefaultGrid,
    FolderPromoMini,
    Tabs,
    InfiniteLoading
  },
  mixins: [needAuth],
  data() {
    return {
      infiniteKey: 1,
      promos: [],
      currentTab: _.get(this.$route.query, 'tab', 'default'),
      fetchOptions: {
        per_page: 12,
        include_filters: ['types', 'morphable_types', 'categories', 'webshops', 'stores', 'brands', 'prices'],
        q: '',
        only_valid_promos: 1,
        only_day_deals: '',
        only_paid_client: '',
        only_recently_created: '',
        only_expires_soon: '',
        vanishes_at: '',
        morphable_types: [],
        categories: [],
        webshops: [],
        stores: [],
        brands: [],
        prices: [],
        order: 'rating_count',
        random_salt: Math.floor(Math.random() * 1000000000),
      }
    }
  },
  computed: {
    /**
     * mapGetters
     */
    ...mapGetters('category', ['CATEGORIES']),
    ...mapGetters('promo', [
      'PROMOS',
      'PROMOS_PROMISE',
      'PROMOS_TOTAL',
      'PROMOS_IS_COMPLETE',
    ]),
    ...mapGetters('filter', [
      'FILTERS_VALUES',
    ]),
    tabs() {
      const tabs = {};
      tabs['default'] = this.$t('promo.tabs.default');
      tabs['recently-created'] = this.$t('promo.tabs.recently-created');
      tabs['expires-soon'] = this.$t('promo.tabs.expires-soon');

      return tabs;
    },
    breakpointDetails() {
      return breakpoint.get(this.$router.currentRoute.value.fullPath);
    },
    autoScrollParams() {
      if (!this.breakpointDetails) {
        return {per_page: this.fetchOptions.per_page}
      }

      const k = this.breakpointDetails.key;

      const perPage = k % 12 === 0 ? k + 12 : Math.ceil(k / 12) * 12;

      return {per_page: perPage, page: perPage / this.fetchOptions.per_page}
    },
    /**
     * Title text
     * @returns {*}
     */
    h1Text() {
      let search = _.get(this.$route.query, 'q', '');
      if (!search.length) {
        search = _.get(this.$route.query, 'search_filter', '');
      }
      let category = this.$t('filters.all');
      const categoryId = Number(_.get(this.$route.query, 'ch_filter[]', 0));
      if (categoryId) {
        category = this.CATEGORIES[_.findIndex(this.CATEGORIES, function (o) {
          return o.id == categoryId;
        })].translation.simplified_title
      }
      return this.$t('promo.promos_page_h1', {search: search, category: category});
    },
    /**
     * Is reset filter button visible
     * @returns {boolean}
     */
    isResetFilterVisible() {
      if (_.get(this.$route.query, 'q', false)) {
        return true;
      }
      return Object.keys(this.FILTERS_VALUES).some(key => {
        if (typeof this.FILTERS_VALUES[key] === 'string' && this.FILTERS_VALUES[key].length) {
          return true;
        }
        return _.isObject(this.FILTERS_VALUES[key]) && Object.keys(this.FILTERS_VALUES[key]).length > 0
      })
    },
    currentQuery() {
      return this.$route.query.q;
    },
  },
  created() {
    useHead({
      // Can be static or computed
      link: [
        {
          rel: 'canonical',
          href: new URL(this.$route.path, window.location.origin).href
        },
      ],
    })
  },
  beforeMount() {
    this.RESET_PROMOS();
    this.RESET_FILTERS();

    this.SET_FILTER_OPENED({ch_filter: false});
    this.SET_FILTER_OPENED({webshops_filter: false});
    this.SET_FILTER_OPENED({stores_filter: false});
    this.SET_FILTER_OPENED({brands_filter: false});

    this.prepareFetchOptions();
  },
  beforeUnmount() {
    this.RESET_FILTERS();
  },
  methods: {
    ...mapActions('filter', [
      'SET_FILTER_ACTIVE',
      'SET_FILTER_INACTIVE',
      'SET_FILTER_OPENED',
      'RESET_FILTERS',
    ]),
    ...mapActions('promo', [
      'FETCH_PROMOS',
      'RESET_PROMOS',
      'UPDATE_CURRENT_PAGE'
    ]),
    saveScrollPosition(key) {
      breakpoint.set(this.$router.currentRoute.value.fullPath, {pos: window.scrollY, key})
    },
    /**
     * Sort Promos
     * @returns {*}
     */
    sortPromos() {
      this.promos = this.promos.sort(function (a, b) {
        return b.data.rating_calculated - a.data.rating_calculated
      })
    },
    prepareFetchOptions() {
      this.isResetFilterVisible ? this.SET_FILTER_ACTIVE('reset_filter') : this.SET_FILTER_INACTIVE('reset_filter');
      this.SET_FILTER_ACTIVE('search_filter');
      this.SET_FILTER_ACTIVE('promos_filter');
      this.SET_FILTER_ACTIVE('ch_filter');
      this.SET_FILTER_ACTIVE('webshops_filter');
      this.SET_FILTER_ACTIVE('stores_filter');
      this.SET_FILTER_ACTIVE('brands_filter');
      this.SET_FILTER_ACTIVE('price_filter');

      this.fetchOptions.q = '';
      this.fetchOptions.morphable_types = [];
      this.fetchOptions.only_day_deals = '';
      this.fetchOptions.types = [];

      const promos_filter = this.FILTERS_VALUES.promos_filter;
      this.fetchOptions.categories = this.FILTERS_VALUES.ch_filter;
      this.fetchOptions.webshops = this.FILTERS_VALUES.webshops_filter;
      this.fetchOptions.stores = this.FILTERS_VALUES.stores_filter;
      this.fetchOptions.brands = this.FILTERS_VALUES.brands_filter;
      this.fetchOptions.order = 'rating_count';

      if (this.FILTERS_VALUES.search_filter) {
        this.fetchOptions.q = this.FILTERS_VALUES.search_filter;
        this.fetchOptions.order = 'search_score';
      }

      if (this.$route.query.q) {
        this.fetchOptions.q = this.$route.query.q;
        this.fetchOptions.order = 'search_score';
      }

      this.fetchOptions.only_valid_promos = 1;
      if (this.fetchOptions.q.length) {
        this.fetchOptions.only_valid_promos = 0;
      }

      const prices = this.FILTERS_VALUES.price_filter;
      this.fetchOptions.prices = prices[0] == null || prices[1] == null ? [] : prices;

      if (promos_filter.includes('discount_code')) {
        if (!this.fetchOptions.morphable_types.includes('Post')) {
          this.fetchOptions.morphable_types.push('Post');
        }
        this.fetchOptions.types.push(this.$consts.POST_TYPES.DISCOUNT);
      }
      if (promos_filter.includes('folder_promos')) {
        this.fetchOptions.morphable_types.push('FolderItem');
      }

      if (promos_filter.includes('post_ecoupon')) {
        if (!this.fetchOptions.morphable_types.includes('Post')) {
          this.fetchOptions.morphable_types.push('Post');
        }
        this.fetchOptions.types.push(this.$consts.POST_TYPES.ECOUPON);
      }

      if (promos_filter.includes('post_cashback')) {
        if (!this.fetchOptions.morphable_types.includes('Post')) {
          this.fetchOptions.morphable_types.push('Post');
        }
        this.fetchOptions.types.push(this.$consts.POST_TYPES.CASHBACK);
      }

      if (promos_filter.includes('post_sale')) {
        if (!this.fetchOptions.morphable_types.includes('Post')) {
          this.fetchOptions.morphable_types.push('Post');
        }
        this.fetchOptions.types.push(this.$consts.POST_TYPES.SALE);
      }

      if (promos_filter.includes('post_free_products')) {
        if (!this.fetchOptions.morphable_types.includes('Post')) {
          this.fetchOptions.morphable_types.push('Post');
        }
        this.fetchOptions.types.push(this.$consts.POST_TYPES.FREE_PRODUCTS);
      }

      if (promos_filter.includes('promos')) {
        this.fetchOptions.morphable_types.push('OnlinePromo', 'Post');
        this.fetchOptions.types.push(this.$consts.POST_TYPES.DEFAULT);
      }

      if (this.fetchOptions.types.length === 0) {
        delete this.fetchOptions.types;
      }

      if (promos_filter.includes('day_deals')) {
        this.fetchOptions.morphable_types.push('OnlinePromo');
        this.fetchOptions.only_day_deals = 1;
      }

      this.fetchOptions.only_paid_client = '';
      this.fetchOptions.only_recently_created = '';
      switch (this.currentTab) {
        case 'recently-created':
          this.fetchOptions.vanishes_at = '';
          this.fetchOptions.only_expires_soon = 0;
          this.fetchOptions.only_recently_created = 1;
          break;
        case 'expires-soon':
          this.fetchOptions.only_expires_soon = 1;
          break;
        case 'default':
        default:
          this.fetchOptions.vanishes_at = '';
          this.fetchOptions.only_expires_soon = 0;

          if (_.every([
            this.fetchOptions.webshops,
            this.fetchOptions.stores,
          ], _.isEmpty) && _.isEmpty(this.fetchOptions.q)) {
            this.fetchOptions.only_paid_client = 1;
            this.fetchOptions.vanishes_at = 90;
          }

          break;
      }
    },
    /**
     * Is promoman visible
     * @param i
     * @returns {boolean|boolean|*}
     */
    isPromomanVisible(i) {
      return (!this.mdAndUp && !this.xsOnly && i === 1)
        || (this.mdAndUp && i === 2)
    },
    tabChange(tabKey) {
      this.currentTab = tabKey;
      this.RESET_PROMOS();
      this.prepareFetchOptions();
      this.loadPromos();
    },
    loadPromos(props = {}) {
      return this.FETCH_PROMOS({...this.fetchOptions, ...props}).then(() => this.promos = this.PROMOS);
    },
    load(state) {
      if (this.PROMOS.length && this.triggerLoginDialog()) {
        state.complete();
        return;
      }

      this.loadPromos().then(() => {
        if (this.PROMOS_IS_COMPLETE) {
          state.complete();
        } else {
          state.loaded();
        }
      });
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    currentQuery(to, from) {
      if (undefined === to) {
        return;
      }

      this.RESET_FILTERS(true).then(() => {
        this.fetchOptions.q = to;
        this.prepareFetchOptions();
        this.RESET_PROMOS();
        this.loadPromos();
      });
    },
    FILTERS_VALUES: {
      // the callback will be called immediately after the start of the observation
      deep: true,
      handler(newVal, oldVal) {
        if (this.$route.name !== 'promos') {
          return;
        }
        const props = {per_page: this.autoScrollParams.per_page}

        if (this.PROMOS_PROMISE) {
          this.PROMOS_PROMISE.then(() => {
            this.RESET_PROMOS();
            this.prepareFetchOptions();
            this.loadPromos().then(() => {
              this.infiniteKey++;
            });
          });
        } else {
          this.RESET_PROMOS();
          this.prepareFetchOptions();
          this.loadPromos(props).then(() => {
            if (this.autoScrollParams.page) {
              this.UPDATE_CURRENT_PAGE(this.autoScrollParams.page)
            }
            this.infiniteKey++;

            if (this.breakpointDetails) {
              window.scrollTo({top: parseInt(this.breakpointDetails.pos), behavior: 'smooth'});
            }
          });
        }

        breakpoint.remove()
      }
    }
  }
}
</script>
