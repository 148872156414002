// plugins
import '@/plugins/cookies'
import '@/plugins/lodash'
import '@/plugins/axios'
import installGoogleAdsence from '@/plugins/adsence'
import installElementPlus from '@/plugins/element'
import installRouter from '@/plugins/router'
import vue3GoogleLogin from 'vue3-google-login'

// modules
import VueAxios from 'vue-axios'
import {VueReCaptcha} from 'vue-recaptcha-v3'
import VueClipboard from 'vue3-clipboard'
import mitt from 'mitt'

// app
import {createApp} from 'vue'
import {createHead} from '@vueuse/head'

// vuex
import store from '@/store'

// mixins
import {display} from '@/mixins/display'
import dialogs from '@/mixins/dialogs';
import {routing} from '@/mixins/routing'
import queries from '@/mixins/guest'
import {carousels} from '@/mixins/carousels';

// scss
import '@/assets/sass/main.scss'

// components
import App from '@/components/App.vue';
import MinApp from '@/components/MinApp.vue';
import SpanLink from '@/components/feed/post/SpanLink';
import Bookmarks from '@/components/left_bar/Bookmarks';
import Tabs from '@/components/feed/Tabs';
import CommentForm from '@/components/feed/comment/CommentForm';
import LeftBar from '@/components/left_bar/LeftBar';
import RightBar from '@/components/right_bar/RightBar';
import FacebookAvatar from '@/components/feed/comment/FacebookAvatar';
import AdSense from '@/components/feed/news/AdSense';
import SingleAdvertiser from '@/components/feed/news/SingleAdvertiser';
import InitSocialRegister from '@/components/header/auth/InitSocialRegister';
import UiAutocomplete from '@/components/header/UiAutocomplete.vue';
import Breadcrumbs from '@/components/header/Breadcrumbs.vue';
import LocaleDropdown from '@/components/header/dropdowns/LocaleDropdown.vue';
import HeaderActions from '@/components/header/HeaderActions.vue';
import LocaleSwitcher from '@/components/header/LocaleSwitcher';
import CategoriesHeaderFilter from '@/components/left_bar/filters/CategoriesHeaderFilter.vue';
import AuthForm from '@/components/feed/AuthForm';
import AuthorInfo from '@/components/feed/AuthorInfo.vue';
import ScrollTop from '@/components/footer/ScrollTop';
// filter components
import SearchFilter from '@/components/left_bar/filters/SearchFilter.vue';
import PromosFilter from '@/components/left_bar/filters/PromosFilter.vue';
import WebshopsFilter from '@/components/left_bar/filters/WebshopsFilter.vue';
import StoresFilter from '@/components/left_bar/filters/StoresFilter.vue';
import PriceFilter from '@/components/left_bar/filters/PriceFilter.vue';
import BrandsFilter from '@/components/left_bar/filters/BrandsFilter.vue';
import CategoriesFilter from '@/components/left_bar/filters/CategoriesFilter.vue';
import ResetFilter from '@/components/left_bar/filters/ResetFilter';

// constants
import constants from '@/plugins/consts.js';
import AdCarousel from '@/components/feed/carousels/AdCarousel.vue';
import EmailSubscriptionSection from '@/views/feed/sections/EmailSubscriptionSection.vue';
import {share} from '@/mixins/post/share';
import Share from '@/components/feed/Share.vue';

localStorage.setItem('VueAppLanguage', cookies.get('locale') || process.env.VUE_APP_FALLBACK_LOCALE);

const app = createApp({});
const emitter = mitt()
const head = createHead()
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.$consts = constants;
installElementPlus(app)
installRouter(app)
installGoogleAdsence(app)
app // seo
    .use(head)
    // vuex
    .use(store)
    // uses
    .use(VueReCaptcha, {siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHAV3_SITEKEY})
    .use(vue3GoogleLogin, {clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID})
    .use(VueClipboard, {
        autoSetContainer: true,
        appendToBody: true,
    })
    .use(VueAxios, axios)
    // mixins
    .mixin(display)
    .mixin(dialogs)
    .mixin(queries)
    .mixin(carousels)
    .mixin(routing)
    .mixin(share)
    .component('app', App)
    .component('min-app', MinApp)
    .component('search-filter', SearchFilter)
    .component('reset-filter', ResetFilter)
    .component('promos-filter', PromosFilter)
    .component('webshops-filter', WebshopsFilter)
    .component('stores-filter', StoresFilter)
    .component('brands-filter', BrandsFilter)
    .component('price-filter', PriceFilter)
    .component('categories-filter', CategoriesFilter)
    .component('categories-header-filter', CategoriesHeaderFilter)
    .component('breadcrumbs', Breadcrumbs)
    .component('header-actions', HeaderActions)
    .component('share', Share)
    .component('ad-carousel', AdCarousel)
    .component('locale-dropdown', LocaleDropdown)
    .component('ui-autocomplete', UiAutocomplete)
    .component('left-bar', LeftBar)
    .component('right-bar', RightBar)
    .component('bookmarks', Bookmarks)
    .component('tabs', Tabs)
    .component('span-link', SpanLink)
    .component('comment-form', CommentForm)
    .component('facebook-avatar', FacebookAvatar)
    .component('ad-sense', AdSense)
    .component('single-advertiser', SingleAdvertiser)
    .component('init-social-register', InitSocialRegister)
    .component('locale-switcher', LocaleSwitcher)
    .component('email-subscription-section', EmailSubscriptionSection)
    .component('auth-form', AuthForm)
    .component('author-info', AuthorInfo)
    .component('scroll-top', ScrollTop)
    // mount
    .mount('#app')
